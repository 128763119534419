import styles from './ContaminantGroupItem.module.css';
import { ContaminantGroup } from 'api/types';
import Contaminants from 'components/v4/Contaminants/Contaminants';
import Collapsible from 'components/Collapsible/Collapsible';
import Checkmark from 'components/Icons/Checkmark';
import BoldSubstring from 'components/BoldSubstring/BoldSubstring';

const ContaminantGroupItem = ({ contaminantGroup }: { contaminantGroup: ContaminantGroup }) => {
  const { name, value, passed, successRate, description, contaminants } = contaminantGroup;

  return (
    <Collapsible
      className={styles.contaminantGroup}
      label={
        <div className={styles.label}>
          <div className={styles.name}>{name}</div>
          {passed && (
            <div className={styles.passed}>
              <Checkmark width={8} height={8} />
              Passed
            </div>
          )}
          <div className={styles.unit}>{value}</div>
        </div>
      }
    >
      <div className={styles.contaminants}>
        {successRate && (
          <div>
            Test Success Rate: <span className={styles.successRate}>{successRate}</span>
          </div>
        )}
        <div>
          <div className={styles.what}>What is this?</div>
          <div>
            <BoldSubstring content={description} boldStrings={['European Union Regulations']} />
          </div>
        </div>
        <Contaminants contaminants={contaminants} />
      </div>
    </Collapsible>
  );
};

export default ContaminantGroupItem;
