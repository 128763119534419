import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    // Adding this to avoid multiple form submissions
    this.debouncedSubmitForm = this.#debounce(this.#submitForm, 400).bind(this);
  }

  submit(event) {
    event.preventDefault();

    // This is to prevent the form to be submitted without
    // the required fields filled
    if (this.#shouldSubmitForm()) {
      this.debouncedSubmitForm();
    }
  }

  removeRow(event) {
    this.element.remove();
  }

  #shouldSubmitForm() {
    const skuField = this.formTarget.querySelector("[name='sample[sku_id]']");
    if (!skuField || !skuField.value) return false;

    const assayField = this.formTarget.querySelector("[name='sample[tests_attributes][0][assay_id]']");
    if (!assayField || !assayField.value) return false;

    const lotField = this.formTarget.querySelector("[name='sample[lot]']");
    if (!lotField || !lotField.value) return false;

    return true;
  }

  #submitForm() {
    this.formTarget.requestSubmit();
  }

  #debounce(fn, delay) {
    let timer = null;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => fn.apply(this, args), delay);
    };
  }
}
