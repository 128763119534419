import styles from './Button.module.css';
import clsx from 'clsx';
import { ComponentChildren } from 'preact';
import ButtonLoader from 'components/Icons/ButtonLoader';

type ButtonProps = {
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
  children?: ComponentChildren;
};
const Button = ({ className, isLoading = false, children, onClick, ...props }: ButtonProps) => {
  const handleClick = () => {
    if (!isLoading) {
      onClick?.();
    }
  };

  return (
    <button className={clsx(className, styles.button)} onClick={handleClick} {...props} type="submit">
      <div className={clsx(styles.loader, { [styles.loading]: isLoading })}>
        <ButtonLoader />
      </div>
      <div className={clsx(styles.label, { [styles.loading]: isLoading })}>{children}</div>
    </button>
  );
};

export default Button;
