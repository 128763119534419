import styles from './ConfigOption.module.css';
import { ComponentChildren } from 'preact';

type ConfigOptionProps = {
  children?: ComponentChildren;
  label?: string;
};
const ConfigOption = (props: ConfigOptionProps) => {
  const { children, label } = props;

  return (
    <div className={styles.configOption}>
      <label>{label}</label>
      <div className={styles.input}>{children}</div>
    </div>
  );
};

export default ConfigOption;
