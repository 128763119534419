import ChevronRight from 'components/Icons/ChevronRight';
import styles from './SelectList.module.css';

type SelectItem = {
  id: string | number;
  label: string;
};

type SelectListProps = {
  items?: SelectItem[];
  onSelect?: (id: string | number) => void;
};

const SelectList = (props: SelectListProps) => {
  const { items, onSelect } = props;
  return (
    <ul className={styles.selectList}>
      {items.map(({ id, label }) => (
        <li onClick={() => onSelect?.(id)}>
          {label} <ChevronRight />
        </li>
      ))}
    </ul>
  );
};

export default SelectList;
