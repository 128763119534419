import StyleSelect from '../StyleSelect/StyleSelect';
import { parseCssVariables } from 'theme/variables';
import { useEffect, useState } from 'preact/hooks';
import styles from './ConfigureLookup.module.css';
import ScriptGenerator from 'components/Configuration/ScriptGenerator/ScriptGenerator';
import { parseConfig } from 'utils/config';
import baseStyles from 'theme/base.module.css';
import clsx from 'clsx';
import { CSSProperties } from 'react';
import Button from 'components/Button/Button';
import Reset from 'components/Icons/Reset';
import { getValue, setValue } from 'utils/storage';
import { pick } from 'utils/nolodash';
import { useConfigContext } from '../../../context/ConfigContext';
import ConfigOption from 'components/Configuration/ConfigOption/ConfigOption';
import TextInput from 'components/TextInput/TextInput';
import DropDown from 'components/DropDown/DropDown';
import Lookup from 'components/Lookup/Lookup';

const defaultConfig = parseConfig();
const defaultVariables = parseCssVariables();
const persistKeys = ['lotLabelText', 'includeFdaLink', 'lotSearchMode'];
const allowedKeys = ['apiKey', ...persistKeys];

const STYLE_STORAGE_KEY = 'lookup-style';
const CONFIG_STORAGE_KEY = 'lookup-config';
const lotSearchModeOptions = ['PRODUCT', 'LOT'].map((key) => ({ id: key, label: key }));

const ConfigureLookup = () => {
  const { config: pageConfig, applyConfig } = useConfigContext();
  const versionDefaults = defaultVariables.lookup ?? {};
  const [variables, setVariables] = useState({ ...versionDefaults, ...getValue(STYLE_STORAGE_KEY) });

  // Only store changes to keys we are allowing the user to modify in the UI
  const [config, setConfig] = useState({
    ...pick(pageConfig, allowedKeys),
    ...(getValue(CONFIG_STORAGE_KEY) ?? pick(defaultConfig, allowedKeys)),
  });

  // If there was a saved config we need to apply it to the actual config
  useEffect(() => {
    applyConfig(config);
  }, []);

  const nonDefaultStyleVariables = Object.keys(versionDefaults).reduce((acc, key) => {
    if (variables[key] !== versionDefaults[key]) {
      return {
        ...acc,
        [key]: variables[key],
      };
    }
    return acc;
  }, {});

  const nonDefaultConfig = allowedKeys.reduce((acc, key) => {
    if (config[key] !== defaultConfig[key]) {
      return {
        ...acc,
        [key]: config[key],
      };
    }
    return acc;
  }, {});

  const handleResetStyles = () => {
    setVariables(versionDefaults);
    setValue(STYLE_STORAGE_KEY, null);
  };

  const handleResetKey = (key: string) => {
    handleChangeStyle(key, versionDefaults[key]);
  };

  const handleChangeStyle = (key: string, value: string) => {
    const updated = {
      ...variables,
      [key]: value,
    };
    setVariables(updated);
    setValue(STYLE_STORAGE_KEY, updated);
  };

  const handleChangeConfig = (key: string, value: string | number | boolean) => {
    const updated = {
      ...config,
      [key]: value,
    };
    setConfig(updated);
    applyConfig(updated);
    setValue(CONFIG_STORAGE_KEY, pick(updated, persistKeys));
  };

  return (
    <div className={clsx(baseStyles.common, styles.configureLookup)}>
      <div>
        <div className={styles.heading}>Configuration Properties</div>
        <div className={styles.configOptions}>
          <ConfigOption label="API Key">
            <TextInput value={config.apiKey} onKeyUp={(text) => handleChangeConfig('apiKey', text)} />
          </ConfigOption>
          <ConfigOption label="Lot Label Text">
            <TextInput value={config.lotLabelText} onKeyUp={(text) => handleChangeConfig('lotLabelText', text)} />
          </ConfigOption>
          <ConfigOption label="Layout Mode">
            <DropDown
              size="small"
              options={lotSearchModeOptions}
              selectedId={config.lotSearchMode}
              onSelect={(id) => handleChangeConfig('lotSearchMode', id)}
              labelClassName={styles.dropdownLabel}
              menuItemClassName={styles.dropdownMenuItem}
            />
          </ConfigOption>
          <ConfigOption label="Include FDA Link">
            <input
              type="checkbox"
              checked={config.includeFdaLink}
              onChange={(e) => handleChangeConfig('includeFdaLink', (e.target as HTMLInputElement).checked)}
            />
          </ConfigOption>
        </div>
        <div className={styles.reset}></div>
        <div className={styles.heading}>Style Properties</div>
        <StyleSelect variables={variables} onChange={handleChangeStyle} onResetKey={handleResetKey} />
        <div className={styles.reset}>
          <Button onClick={handleResetStyles}>
            <Reset /> Reset Styles
          </Button>
        </div>
      </div>
      <div className={styles.output}>
        <Lookup style={variables as CSSProperties} />
        <ScriptGenerator
          config={nonDefaultConfig}
          styleVariables={nonDefaultStyleVariables}
          scriptPath="https://app.lightlabs.com/assets/ll-product-lookup.js"
          mountId="ll-product-lookup"
        />
      </div>
    </div>
  );
};

export default ConfigureLookup;
