import * as React from 'react';
import { SVGProps } from 'react';
const Arrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={9} height={9} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.00628 5.4H1.97428L4.81828 8.244L4.09828 8.988L0.00628129 4.884L4.11028 0.792L4.83028 1.536L1.97428 4.38L9.00628 4.368V5.4Z"
      fill="white"
    />
  </svg>
);
export default Arrow;
