import { HTMLAttributes } from 'react';
import styles from './Loader.module.css';

type LoaderProps = HTMLAttributes<HTMLDivElement>;

const Loader = (props: LoaderProps) => {
  return <div class={styles.loader} {...props} />;
};

export default Loader;
