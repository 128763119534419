import { ComponentChildren, JSX } from 'preact';

type FormProps = JSX.IntrinsicElements['form'];

const Form = ({ children, ...props }: FormProps) => {
  return (
    <form {...props} onSubmit={(e) => e.preventDefault()}>
      {children}
    </form>
  );
};

export default Form;
