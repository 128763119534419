import { useEffect, useState } from 'preact/hooks';
import logger from 'utils/logger';
import { getCacheValue, setCacheValue } from 'utils/queryCache';

export type QueryArgs<T> = {
  query: () => Promise<T>;
  key?: string | string[];
  isEnabled?: boolean;
};

export const useQuery = <T>({ query, key = [], isEnabled = true }: QueryArgs<T>) => {
  const [result, setResult] = useState<T | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const queryKey = (Array.isArray(key) ? key : [key]).sort().join(':');

  const fetchTestResults = async () => {
    try {
      setIsLoading(true);
      const cachedResult = getCacheValue(queryKey);
      const result = cachedResult ? cachedResult : await query();

      logger.log('Result:', result, 'Cached:', !!cachedResult);
      setCacheValue(queryKey, result);
      setResult(result);
      setError(undefined);
    } catch (error) {
      logger.error(error);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEnabled) {
      fetchTestResults();
    }
  }, [queryKey, isEnabled]);

  return {
    result,
    error,
    isLoading,
  };
};
