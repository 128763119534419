import { Lot, ProductSku } from 'api/types';
import BackButton from 'components/Lookup/BackButton/BackButton';
import { chunk } from 'utils/array';
import { formatDate } from 'utils/dates';
import styles from './TestResults.module.css';
import { useConfigContext } from '../../../context/ConfigContext';

type TestResultsProps = {
  test: Lot;
  sku: ProductSku;
  onBack?: () => void;
};

const TestResults = ({ sku, test, onBack }: TestResultsProps) => {
  const { config } = useConfigContext();
  const { lot, testDate, analytes, sku: lotSku } = test ?? {};
  const rows = chunk(analytes, 2);

  return (
    <div className={styles.testResults}>
      <div className={styles.header}>
        <BackButton onClick={onBack} />
        {lot}
      </div>
      <div className={styles.content}>
        <div className={styles.productName}>
          <div>{sku?.name ?? lotSku?.name}</div>
        </div>
        <div className={styles.testInfo}>
          <div>Last Test Date: {formatDate(testDate)}</div>
          <div>
            {config.lotLabelText} {lot}
          </div>
        </div>
        <div className={styles.testMessage}>
          The lot has been <span className={styles.accent}>rigorously tested</span> for contaminants.
        </div>
        <div className={styles.resultsLabel}>Results</div>
        <div className={styles.resultValues}>
          {rows.map((current) => (
            <div className={styles.resultRow}>
              {current.map(({ name, result }) => (
                <div className={styles.resultBox}>
                  <div className={styles.resultName}>{name}</div>
                  <div className={styles.resultValue}>{result}</div>
                </div>
              ))}
              {current.length === 1 && <div className={styles.resultEmpty} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestResults;
