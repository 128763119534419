import { Lot, ProductSku } from 'api/types';
import styles from './LotList.module.css';
import ChevronRight from 'components/Icons/ChevronRight';
import { formatDate } from 'utils/dates';
import Loading from 'components/Lookup/Loading/Loading';
import BackButton from 'components/Lookup/BackButton/BackButton';
import Button from 'components/Button/Button';
import { useConfigContext } from '../../../context/ConfigContext';
import { useLots } from 'hooks/useLots';

type LotListProps = {
  sku?: ProductSku;
  onBack?: () => void;
  onSelect?: (result: Lot) => void;
};

const parseTime = (value: string) => {
  const date = Date.parse(value);
  return !!date ? date : 0;
};

const LotList = ({ sku, onBack, onSelect }: LotListProps) => {
  const { config } = useConfigContext();
  const { isLoading, lots } = useLots(sku && sku.id);

  const mostRecentIndex = lots.reduce((latest, { testDate }, index) => {
    return parseTime(testDate) > parseTime(lots[latest]?.testDate) ? index : latest;
  }, -1);

  const handleMostRecent = () => {
    const lot = lots[mostRecentIndex];
    if (lot) {
      onSelect?.(lot);
    }
  };

  return (
    <div className={styles.lotList}>
      <div className={styles.header}>
        <BackButton onClick={onBack} />
        {sku?.name}
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.content}>
          <div className={styles.list}>
            <table>
              <thead>
                <tr>
                  <th>{config.lotLabelText}</th>
                  <th>Test Date</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {lots.map((sku, index) => (
                  <tr onClick={() => onSelect?.(sku)}>
                    <td>{sku.lot}</td>
                    <td>{formatDate(sku.testDate, 'short')}</td>
                    <td className={styles.mostRecent}>
                      {index === mostRecentIndex && <span className={styles.badge}>Most Recent</span>}
                    </td>
                    <td className={styles.chevron}>
                      <ChevronRight />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button className={styles.cta} onClick={handleMostRecent}>
            View Most Recent Test
          </Button>
        </div>
      )}
    </div>
  );
};

export default LotList;
