import { ProductAPI } from 'api/product';
import { useQuery } from 'hooks/useQuery';
import { ProductSku } from 'api/types';
import { useConfigContext } from '../context/ConfigContext';

type ProductsHook = {
  isLoading: boolean;
  error?: string;
  skus?: ProductSku[];
};
export const useSkus = (): ProductsHook => {
  const { config } = useConfigContext();
  const { apiKey } = config;

  const { isLoading, error, result } = useQuery({
    key: ['getSkus', apiKey],
    query: () => ProductAPI.getSkus(apiKey),
    isEnabled: !!apiKey,
  });

  return {
    skus: result,
    error,
    isLoading,
  };
};
