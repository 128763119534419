import styles from 'components/Lookup/Loading/Loading.module.css';
import Loader from 'components/Icons/Loader/Loader';

const Loading = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.loader}>
        <Loader width={50} height={50} />
      </div>
    </div>
  );
};

export default Loading;
