import StyleSelect from '../StyleSelect/StyleSelect';
import { parseCssVariables } from 'theme/variables';
import { useEffect, useState } from 'preact/hooks';
import styles from './ConfigurePip.module.css';
import ScriptGenerator from 'components/Configuration/ScriptGenerator/ScriptGenerator';
import { parseConfig } from 'utils/config';
import baseStyles from 'theme/base.module.css';
import clsx from 'clsx';
import { CSSProperties } from 'react';
import Button from 'components/Button/Button';
import Reset from 'components/Icons/Reset';
import { getValue, setValue } from 'utils/storage';
import { pick } from 'utils/nolodash';
import ConnectedVersionedWidget from 'components/VersionedWidget/ConnectedVersionedWidget';
import TextInput from 'components/TextInput/TextInput';
import DropDown from 'components/DropDown/DropDown';
import ConfigOption from 'components/Configuration/ConfigOption/ConfigOption';
import { useConfigContext } from '../../../context/ConfigContext';

const defaultConfig = parseConfig();
const defaultVariables = parseCssVariables();
const persistKeys = ['layoutVersion'];
const allowedKeys = ['companyId', ...persistKeys];

const STYLE_STORAGE_KEY = 'pip-style';
const CONFIG_STORAGE_KEY = 'pip-config';

const LayoutVersions = new Array(4).fill(null).map((_, index) => {
  const id = index + 1;
  return { id, label: id.toString() };
});

const ConfigurePip = () => {
  const { config: pageConfig, applyConfig } = useConfigContext();

  // Only store changes to keys we are allowing the user to modify in the UI
  const [config, setConfig] = useState({
    ...pick(pageConfig, allowedKeys),
    ...(getValue(CONFIG_STORAGE_KEY) ?? pick(defaultConfig, allowedKeys)),
  });

  const versionDefaults = defaultVariables[`v${config.layoutVersion}`] ?? {};
  const [variables, setVariables] = useState({ ...versionDefaults, ...getValue(STYLE_STORAGE_KEY) });

  // If there was a saved config we need to apply it to the actual config
  useEffect(() => {
    applyConfig(config);
  }, []);

  const nonDefaultStyleVariables = Object.keys(versionDefaults).reduce((acc, key) => {
    if (variables[key] !== versionDefaults[key]) {
      return {
        ...acc,
        [key]: variables[key],
      };
    }
    return acc;
  }, {});

  const nonDefaultConfig = allowedKeys.reduce((acc, key) => {
    if (config[key] !== defaultConfig[key]) {
      return {
        ...acc,
        [key]: config[key],
      };
    }
    return acc;
  }, {});

  const handleResetStyles = (version?: number) => {
    const defaults = defaultVariables[`v${version ?? config.layoutVersion}`];
    setVariables(defaults);
    setValue(STYLE_STORAGE_KEY, null);
  };

  const handleResetKey = (key: string) => {
    handleChangeStyle(key, versionDefaults[key]);
  };

  const handleChangeStyle = (key: string, value: string) => {
    const updated = {
      ...variables,
      [key]: value,
    };
    setVariables(updated);
    setValue(STYLE_STORAGE_KEY, updated);
  };

  const handleChangeConfig = (key: string, value: string | number) => {
    const updated = {
      ...config,
      [key]: value,
    };
    setConfig(updated);
    applyConfig(updated);
    setValue(CONFIG_STORAGE_KEY, pick(updated, persistKeys));
  };

  const handleChangeLayoutVersion = (version: number) => {
    handleChangeConfig('layoutVersion', version);
    handleResetStyles(version);
  };

  return (
    <div className={clsx(baseStyles.common, styles.configurePip)}>
      <div>
        <div className={styles.heading}>Configuration Properties</div>

        <div className={styles.configOptions}>
          <ConfigOption label="Company ID">
            <TextInput value={config.companyId} onKeyUp={(text) => handleChangeConfig('companyId', text)} />
          </ConfigOption>
          <ConfigOption label="Layout Version">
            <DropDown
              options={LayoutVersions}
              selectedId={config.layoutVersion}
              onSelect={handleChangeLayoutVersion}
              size="small"
            />
          </ConfigOption>
        </div>

        <div className={styles.reset}></div>
        <div className={styles.heading}>Style Properties</div>
        <StyleSelect variables={variables} onChange={handleChangeStyle} onResetKey={handleResetKey} />
        <div className={styles.reset}>
          <Button onClick={handleResetStyles}>
            <Reset /> Reset Styles
          </Button>
        </div>
      </div>
      <div className={styles.output}>
        <ConnectedVersionedWidget style={variables as CSSProperties} />
        <ScriptGenerator
          config={nonDefaultConfig}
          styleVariables={nonDefaultStyleVariables}
          scriptPath="https://app.lightlabs.com/assets/ll-product-pip.js"
          mountId="ll-product-pip"
        />
      </div>
    </div>
  );
};

export default ConfigurePip;
