import styles from './DropDown.module.css';
import { useEffect, useRef, useState } from 'preact/hooks';
import clsx from 'clsx';
import DropdownArrow from '../Icons/DropdownArrow';

export type DropDownOption = {
  id: string | number;
  label: string;
};

export type DropDownProps = {
  selectedId?: string | number;
  options?: DropDownOption[];
  onSelect?: (id?: string | number) => void;
  labelClassName?: string;
  menuItemClassName?: string;
  size?: 'small' | 'default';
};

const DropDown = (props: DropDownProps) => {
  const { selectedId, options, onSelect, labelClassName, menuItemClassName, size } = props;
  const [searchOpen, setSearchOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const getSelectedLabel = (value: string | number): string => {
    const selectedItem = options.find(({ id }) => value === id);
    return selectedItem?.label ?? '';
  };

  const handleOpenSelect = () => {
    setSearchOpen(!searchOpen);
  };
  const handleCloseSelect = () => {
    setSearchOpen(false);
  };
  const handleSelect = (id: string | number) => {
    onSelect?.(id);
    handleCloseSelect();
  };

  useEffect(() => {
    const closeMenu = (event: MouseEvent) => {
      // Use composedPath to find the target since event.target will be incorrect in a shadow DOM
      const target = event.composedPath()[0] as HTMLDivElement;
      if (!containerRef.current || !containerRef.current.contains(target)) {
        handleCloseSelect();
      }
    };
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, [containerRef]);

  return (
    <div className={styles.dropdown} ref={containerRef}>
      <div className={clsx(styles.selectedLabel, styles[size], labelClassName)} tabIndex={0} onClick={handleOpenSelect}>
        <span className={styles.labelValues}>{getSelectedLabel(selectedId)}</span>
        <DropdownArrow className={clsx(styles.openIcon, { [styles.openIconRotate]: searchOpen })} />
      </div>
      <div className={styles.menuAnchor}>
        <div className={clsx(styles.menu, { [styles.open]: searchOpen })}>
          <div>
            {options.map(({ label, id }, index) => {
              return (
                <div
                  className={clsx(styles.menuItem, menuItemClassName, styles[size], {
                    [styles.selectedItem]: selectedId === id,
                  })}
                  onClick={() => handleSelect(id)}
                >
                  {label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
