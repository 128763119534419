import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  static values = {
    userSettings: Object,
  };

  connect() {
    if (this.element) {
      this.select = new TomSelect(this.element, this.userSettingsValue);
    }
  }

  disconnect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}
