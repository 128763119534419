import { h, render } from 'preact';
import { Controller } from '@hotwired/stimulus';
import ConfigurePip from '../pdp/src/components/Configuration/ConfigurePip/ConfigurePip';
import { updateConfig } from '../pdp/src/utils/config';
import { ConfigProvider } from '../pdp/src/context/ConfigContext';

const App = () => {
  return (
    <ConfigProvider>
      <ConfigurePip />
    </ConfigProvider>
  );
};
export default class extends Controller {
  static values = {
    companyId: String,
  };

  connect() {
    updateConfig({ companyId: this.companyIdValue, apiUrl: '', debug: true });
    render(<App />, this.element);
  }
}
