import styles from './CopyTextButton.module.css';
import { JSX, ComponentChildren } from 'preact';
import Copy from 'components/Icons/Copy';

type CopyTextButtonProps = {
  children: ComponentChildren;
  copyText: string;
};

const CopyTextButton = ({ children, copyText }: CopyTextButtonProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
  };

  return (
    <div className={styles.copyTextButton}>
      <Copy className={styles.copyIcon} onClick={handleCopy} />
      {children}
    </div>
  );
};

export default CopyTextButton;
