import { LotResponse, LotSearchResponse, Product, ProductResponse, SkuResponse } from './types';
import { request } from './request';
import { config } from 'utils/config';

export const ProductAPI = {
  getProduct: async (companyId: string): Promise<ProductResponse> => {
    return request({ url: `${config.apiUrl}/api/companies/${companyId}/embed_configurations` });
  },

  getSkus: async (companyId: string): Promise<SkuResponse> => {
    return request({ url: `${config.apiUrl}/api/companies/${companyId}/skus` });
  },

  getLots: async (companyId: string, skuId: string): Promise<LotResponse> => {
    return request({
      url: `${config.apiUrl}/api/companies/${companyId}/skus/${skuId}/lots`,
    });
  },

  searchLots: async (companyId: string, lot: string): Promise<LotSearchResponse> => {
    return request({
      url: `${config.apiUrl}/api/companies/${companyId}/lots?lot=${lot}`,
    });
  },
};
