import styles from './Active.module.css';
import { Bar } from 'api/types';
import Collapsible from 'components/Collapsible/Collapsible';
import CircleCheck from 'components/Icons/CircleCheck';

const Active = ({ bar }: { bar: Bar }) => {
  const { name, verified, claim, actual, units } = bar;
  return (
    <Collapsible
      className={styles.active}
      label={
        <div className={styles.label}>
          <span>{name}</span>
          <span>
            {verified && (
              <div className={styles.verified}>
                <CircleCheck />
                Claim Verified
              </div>
            )}
          </span>
        </div>
      }
    >
      <div className={styles.content}>
        {verified
          ? 'The ingredient in this product has been verified to match the amounts stated on the label.'
          : `This product has been verified to contain ${actual}${units} of ${name}, compared to the claim of ${claim}${units}.`}
      </div>
    </Collapsible>
  );
};

export default Active;
