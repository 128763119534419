import { createContext } from 'preact';
import { config, getDataAttributes, parseConfig, scriptElement, updateConfig } from 'utils/config';
import { ProductConfig } from 'api/types';
import { useContext, useEffect, useState } from 'preact/hooks';
import { getQueryParams } from 'utils/location';
import logger from 'utils/logger';

export type ConfigContextProps = {
  config?: ProductConfig;
  applyConfig?: (changes: Partial<ProductConfig>) => void;
};

const ConfigContext = createContext<ConfigContextProps>({});

export const ConfigProvider = ({ children }) => {
  const [stateConfig, setStateConfig] = useState<ProductConfig>(config);
  const queryParams = getQueryParams();
  const { variant } = queryParams;

  const applyConfig = (changes: Partial<ProductConfig>) => {
    // Update the global config
    updateConfig(changes);
    logger.info('Updated Script Config', config);

    // Set a copy of the global config in state to force a render
    setStateConfig({ ...config });
  };

  // Watch all attributes of the script tag and apply changes to the global config
  useEffect(() => {
    const observer = new MutationObserver((mutation) => {
      const attributeChanged = mutation.some(({ type }) => type === 'attributes');
      if (attributeChanged) {
        // Pull all updated data attributes
        const dataAttributes = getDataAttributes(scriptElement);
        applyConfig(dataAttributes);
      }
    });
    if (scriptElement) {
      observer.observe(scriptElement, { attributes: true });
    }

    // Poll the ShopifyAnalytics object for variant data as a fallback to
    // looking at the query param
    const interval = setInterval(() => {
      const { selectedVariantId } = window.ShopifyAnalytics?.meta ?? {};
      if (selectedVariantId && selectedVariantId !== stateConfig.variant) {
        logger.info('Applying variant', selectedVariantId);
        applyConfig({ variant: selectedVariantId });
      }
    }, 1000);

    return () => {
      observer.disconnect();
      clearInterval(interval);
    };
  }, []);

  // If the variant query param is updated, apply it to the config
  useEffect(() => {
    // Don't apply the query param variant if already set by a data attribute
    if (variant && !stateConfig.variant) {
      applyConfig({ variant });
    }
  }, [variant]);

  const value = {
    config: stateConfig,
    applyConfig,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => useContext(ConfigContext);
