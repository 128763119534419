import styles from './LotSearch.module.css';
import TextInput from 'components/TextInput/TextInput';
import { Lot } from 'api/types';
import { useEffect, useState } from 'preact/hooks';
import Button from 'components/Button/Button';
import { useLotSearch } from 'hooks/useLotSearch';
import Form from 'components/Form/Form';
import SelectList from 'components/Lookup/SelectList/SelectList';

type LotSearchProps = {
  onSelect?: (lot: Lot) => void;
};

const lotId = (lot: Lot) => {
  return `${lot.lot}:${lot?.sku?.id}`;
};

const LotSearch = (props: LotSearchProps) => {
  const { onSelect } = props;
  const [searchText, setSearchText] = useState('');
  const [lotSearchText, setLotSearchText] = useState('');

  const { lots, isLoading } = useLotSearch(lotSearchText);

  const handleSearchLot = () => {
    setLotSearchText(searchText);
  };

  const handleSelectLot = (id: string) => {
    const lot = lots?.find((lot) => lotId(lot) === id);
    onSelect?.(lot);
  };

  useEffect(() => {
    if (lots?.length === 1) {
      onSelect?.(lots[0]);
    }
  }, [lots]);

  return (
    <Form className={styles.lotSearch}>
      <div className={styles.header}>Search</div>
      {lots?.length === 0 && <div className={styles.noResults}>We're working on rolling out testing for this lot.</div>}
      <div className={styles.subtitle}>{lots?.length > 1 ? 'Products' : 'Lot #'}</div>
      {lots?.length > 1 && (
        <SelectList
          onSelect={handleSelectLot}
          items={lots.map((lot) => ({ id: lotId(lot), label: `${lot.sku.product.name} - ${lot.sku.name}` }))}
        />
      )}
      <TextInput className={styles.searchInput} value={searchText} onKeyUp={setSearchText} />
      <Button className={styles.cta} onClick={handleSearchLot} isLoading={isLoading}>
        Search
      </Button>
    </Form>
  );
};

export default LotSearch;
