import { Controller } from '@hotwired/stimulus';
import { render } from 'preact';
import { updateConfig } from '../pdp/src/utils/config';
import { default as Widget1 } from '../pdp/src/components/v1/Widget/Widget';
import { default as Widget2 } from '../pdp/src/components/v2/Widget/Widget';
import { default as Widget3 } from '../pdp/src/components/v3/Widget/Widget';
import { h, Fragment } from 'preact';
import { ConfigProvider } from '../pdp/src/context/ConfigContext';

export default class extends Controller {
  static values = {
    layoutVersion: Number,
    product: Object,
    config: Object,
    variant: String,
  };

  connect() {
    const Widget = this.widget(this.layoutVersionValue);

    // Manually inject the variant here-- TODO: turn this into a prop?
    updateConfig({ variant: this.variantValue });

    const App = () => {
      return (
        <ConfigProvider>
          <Widget product={this.productValue} style={this.configValue.theme} />
        </ConfigProvider>
      );
    };

    render(<App />, this.element);
  }

  widget(layoutVersion) {
    switch (layoutVersion) {
      case 3:
        return Widget3;
      case 2:
        return Widget2;
      default:
        return Widget1;
    }
  }
}
