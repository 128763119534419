import { ProductAPI } from 'api/product';
import { Lot } from 'api/types';
import { useQuery } from 'hooks/useQuery';
import { useConfigContext } from '../context/ConfigContext';

type LotsHook = {
  isLoading: boolean;
  error?: string;
  lots?: Lot[];
};
export const useLots = (skuId?: string): LotsHook => {
  const { config } = useConfigContext();

  const { isLoading, error, result } = useQuery({
    key: ['getLots', config.apiKey, skuId],
    query: () => ProductAPI.getLots(config.apiKey, skuId),
    isEnabled: !!skuId,
  });
  const { lots = [] } = result ?? {};

  return {
    lots,
    error,
    isLoading,
  };
};
