import { ProductConfig } from 'api/types';
import styles from './ScriptGenerator.module.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CopyTextButton from 'components/CopyTextButton/CopyTextButton';

type ScriptGeneratorProps = {
  config: Partial<ProductConfig>;
  styleVariables: Record<string, string>;
  allowedConfigKeys?: string[];
  scriptPath: string;
  mountId: string;
};

const buildMountElement = (mountId: string, styleVariables: Record<string, string>) => {
  const styleAttribute =
    Object.keys(styleVariables).length === 0
      ? ''
      : ` style="
${Object.keys(styleVariables)
  .map((key) => ` ${key}: ${styleVariables[key]};`)
  .join('\n')}"`;

  return `<div id="${mountId}"${styleAttribute}>
</div>`;
};
const keyToDataAttribute = (key: string) => {
  return key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};
const buildScriptTag = (scriptPath: string, config: Partial<ProductConfig>) => {
  return `<script src="${scriptPath}"
${Object.keys(config)
  .map((key) => `  data-${keyToDataAttribute(key)}="${config[key]}"`)
  .join('\n')}>
</script>`;
};

const ScriptGenerator = ({ scriptPath, mountId, config, styleVariables }: ScriptGeneratorProps) => {
  const mountHtml = buildMountElement(mountId, styleVariables);
  const mountScript = buildScriptTag(scriptPath, config);

  return (
    <div className={styles.scriptGenerator}>
      <div className={styles.mountScript}>
        Place this element where you want the PTP to render in your HTML
        <CopyTextButton copyText={mountHtml}>
          <SyntaxHighlighter language="html" style={github}>
            {mountHtml}
          </SyntaxHighlighter>
        </CopyTextButton>
      </div>
      <div className={styles.widgetScript}>
        Place this element anywhere below the above element
        <CopyTextButton copyText={mountScript}>
          <SyntaxHighlighter language="html" style={github}>
            {mountScript}
          </SyntaxHighlighter>
        </CopyTextButton>
      </div>
    </div>
  );
};

export default ScriptGenerator;
