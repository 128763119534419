import variables from './variables.module.css?inline';

// Manually parse the CSS file to map the CSS variables by class name
export const parseCssVariables = (): Record<string, Record<string, string>> => {
  // Split class definitions apart
  const cssClasses = variables.split('}');

  // Combine into a single object
  return cssClasses.reduce((acc, css) => {
    // Parse class names from property definitions
    const [classNames, body] = css.split('{');
    if (!body) {
      return acc;
    }

    try {
      // Double quote all the CSS key and values
      const props = body
        .split(';')
        .map((line) => {
          const [key, value] = line.trim().split(':');
          if (!key || !value) {
            return '';
          }
          return `"${key.trim()}": "${value.trim()}"`;
        })
        .filter(Boolean)
        .join(',\n');

      // Parse the CSS properties to an object
      const objectString = `{${props}}`;
      const propsObject = JSON.parse(objectString);

      // Create the object for each comma separated class name
      const names = classNames.split(',');
      const variableObject = names.reduce((nameAcc, name) => {
        const [_, cleanName] = name.replace(/\./, '').split('_');
        return {
          ...nameAcc,
          [cleanName]: propsObject,
        };
      }, {});

      return {
        ...acc,
        ...variableObject,
      };
    } catch (e) {
      console.error('Failed to parse variables.module.css', e);
    }
  }, {});
};

export const allVariables = () => {
  const defaultVariables = parseCssVariables();
  const variableMap = {};
  for (const className in defaultVariables) {
    for (const key in defaultVariables[className]) {
      variableMap[key] = key;
    }
  }
};

// Get all the unique variable names from all classes
export const allVariableNames = () => {
  const defaultVariables = parseCssVariables();
  const variableMap = {};
  for (const className in defaultVariables) {
    for (const key in defaultVariables[className]) {
      variableMap[key] = key;
    }
  }
  return Object.keys(variableMap);
};

export const toFormName = (styleVariable: string) => {
  return styleVariable.replace('--ll-', '').replace(/-/g, '_');
};

// Return the variable names formatted in a safe way for form inputs
export const formVariableNames = () => {
  return allVariableNames().map(toFormName);
};
