import ProductList from 'components/Lookup/ProductList/ProductList';
import { JSX } from 'preact';
import { Lot, LotSearchMode, ProductSku } from 'api/types';
import LotSearch from 'components/Lookup/LotSearch/LotSearch';

type ProductSearchProps = JSX.IntrinsicElements['div'] & {
  lotSearchMode: LotSearchMode;
  onSelectSku?: (sku: ProductSku) => void;
  onSelectLot?: (lot: Lot) => void;
};

const ProductSearch = (props: ProductSearchProps) => {
  const { lotSearchMode, onSelectSku, onSelectLot } = props;

  return (
    <div>
      {lotSearchMode.toUpperCase() === 'LOT' ? (
        <LotSearch onSelect={onSelectLot} />
      ) : (
        <ProductList onSelect={onSelectSku} />
      )}
    </div>
  );
};

export default ProductSearch;
