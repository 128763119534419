import { h, render } from 'preact';
import { Controller } from '@hotwired/stimulus';
import ConfigureLookup from '../pdp/src/components/Configuration/ConfigureLookup/ConfigureLookup';
import { updateConfig } from '../pdp/src/utils/config';
import { ConfigProvider } from '../pdp/src/context/ConfigContext';

const App = () => {
  return (
    <ConfigProvider>
      <ConfigureLookup />
    </ConfigProvider>
  );
};

export default class extends Controller {
  static values = {
    apiKey: String,
  };

  connect() {
    updateConfig({ apiKey: this.apiKeyValue, apiUrl: '' });
    render(<App />, this.element);
  }
}
