import styles from './StyleSelect.module.css';
import Reset from 'components/Icons/Reset';
import { cssVariableToLabel } from 'utils/formatters';
import TextInput from 'components/TextInput/TextInput';
import { useRef } from 'preact/hooks';

type StyleSelectProps = {
  variables: Record<string, string>;
  onChange(key: string, value: string);
  onResetKey(key: string);
};

const labelMap = {
  '--ll-cta-background-color': 'CTA Background Color',
};

const formatLabel = (key: string) => {
  if (labelMap[key]) {
    return labelMap[key];
  }
  return cssVariableToLabel(key);
};

const isColor = (name: string) => name.endsWith('-color');

const StyleSelect = ({ variables, onChange, onResetKey }: StyleSelectProps) => {
  const colorPickerRef = useRef({});
  const styleKeys = Object.keys(variables).sort((a, b) => {
    if (isColor(a) > isColor(b)) return -1;
    if (isColor(b) > isColor(a)) return 1;
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  });

  const handleOpenColorPicker = (key: string) => {
    if (colorPickerRef.current[key]) {
      colorPickerRef.current[key].click();
    }
  };
  const handlePickerRef = (key: string, ref: HTMLInputElement) => {
    colorPickerRef.current[key] = ref;
  };

  return (
    <div className={styles.styleSelect} style={variables}>
      <table>
        <tbody>
          {styleKeys.map((key) => (
            <tr className={styles.option}>
              <td>
                <Reset className={styles.optionReset} onClick={() => onResetKey(key)} />
              </td>
              <td>{formatLabel(key)}</td>
              <td>
                <TextInput value={variables[key]} onKeyUp={(value) => onChange(key, value)} />
              </td>
              <td>
                {isColor(key) && (
                  <div
                    className={styles.optionColor}
                    style={{ backgroundColor: `var(${key})` }}
                    onClick={() => handleOpenColorPicker(key)}
                  >
                    <input
                      ref={(ref) => handlePickerRef(key, ref)}
                      className={styles.colorPicker}
                      type="color"
                      onChange={(e) => onChange(key, (e.target as HTMLInputElement).value)}
                    />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StyleSelect;
