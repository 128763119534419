import VersionedWidget from 'components/VersionedWidget/VersionedWidget';
import { useProduct } from 'hooks/useProduct';
import styles from 'theme/base.module.css';
import { JSX } from 'preact';
import { useConfigContext } from '../../context/ConfigContext';

type ConnectedVersionedWidgetProps = JSX.IntrinsicElements['div'] & {};

const ConnectedVersionedWidget = (props: ConnectedVersionedWidgetProps) => {
  const { product } = useProduct();
  const { config: stateConfig } = useConfigContext();

  if (!product) {
    return null;
  }

  return (
    <div className={styles.common}>
      <VersionedWidget
        version={stateConfig.layoutVersion}
        product={product}
        hideActiveIngredients={stateConfig.hideActiveIngredients}
        hideContaminants={stateConfig.hideContaminants}
        {...props}
      />
    </div>
  );
};

export default ConnectedVersionedWidget;
