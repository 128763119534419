import styles from './Contaminants.module.css';
import { Contaminant } from 'api/types';
import { chunk } from 'utils/array';
import { useState } from 'preact/hooks';
import clsx from 'clsx';

const Contaminants = ({ contaminants }: { contaminants: Contaminant[] }) => {
  const [minUnitWidth, setMinUnitWidth] = useState(0);
  const checkUnitWidth = (element: HTMLSpanElement) => {
    const { width = 0 } = element?.getBoundingClientRect() ?? {};
    setMinUnitWidth((val) => Math.max(val, width));
  };

  // Chunk contaminants into groups of two
  const rows = chunk(contaminants, 2);

  return (
    <div className={styles.contaminants}>
      {rows.map((current) => (
        <div className={styles.contaminantsRow}>
          {current.map(({ name, value, outOfSpec }) => (
            <div className={styles.contaminantsBox}>
              <span className={styles.contaminantsName}>{name}</span>
              <span
                style={{ minWidth: minUnitWidth }}
                className={clsx(styles.contaminantsUnit, { [styles.outOfSpec]: outOfSpec })}
                ref={checkUnitWidth}
              >
                {outOfSpec && '• '}
                {value}
              </span>
            </div>
          ))}
          {current.length === 1 && <div className={styles.singleContaminant}></div>}
        </div>
      ))}
    </div>
  );
};

export default Contaminants;
