import { ProductSku } from 'api/types';
import styles from './ProductList.module.css';
import ChevronRight from 'components/Icons/ChevronRight';
import TextInput from 'components/TextInput/TextInput';
import { useState } from 'preact/hooks';
import Loading from 'components/Lookup/Loading/Loading';
import { useSkus } from 'hooks/useSkus';
import SelectList from 'components/Lookup/SelectList/SelectList';

type ProductListProps = {
  onSelect?: (sku: ProductSku) => void;
};

const ProductList = ({ onSelect }: ProductListProps) => {
  const { isLoading, skus = [] } = useSkus();
  const [searchText, setSearchText] = useState('');
  const filteredSkus = skus.filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()));

  const handleSearchProduct = (text: string) => {
    setSearchText(text);
  };

  const handleSelectSku = (skuId: string) => {
    const sku = filteredSkus.find(({ id }) => skuId === id);
    onSelect?.(sku);
  };

  return (
    <div className={styles.productList}>
      <div className={styles.header}>Search</div>
      <div className={styles.subtitle}>Product</div>
      <TextInput className={styles.searchInput} value={searchText} onKeyUp={handleSearchProduct} />
      {isLoading ? (
        <Loading />
      ) : (
        <SelectList onSelect={handleSelectSku} items={filteredSkus.map(({ id, name }) => ({ id, label: name }))} />
      )}
    </div>
  );
};

export default ProductList;
