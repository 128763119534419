import styles from './TextInput.module.css';
import clsx from 'clsx';

type TextInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  onKeyUp?: (value: string) => void;
  className?: string;
};
const TextInput = ({ className, onChange, onKeyUp, ...props }: TextInputProps) => {
  const handleChange = (e) => {
    onChange?.(e.target.value);
  };
  const handleKeyUp = (e) => {
    onKeyUp?.(e.target.value);
  };

  return (
    <input className={clsx(className, styles.textInput)} onChange={handleChange} onKeyUp={handleKeyUp} {...props} />
  );
};

export default TextInput;
