const STORAGE_KEY = 'lightlabs';

const getStorageObject = () => {
  try {
    const item = localStorage.getItem(STORAGE_KEY);
    return item === null ? {} : JSON.parse(item);
  } catch (e) {
    return {};
  }
};

export const setValue = (key: string, value: any) => {
  const storage = getStorageObject();
  storage[key] = JSON.stringify(value);

  localStorage.setItem(STORAGE_KEY, JSON.stringify(storage));
};

export const getValue = (key: string) => {
  const item = getStorageObject()[key];
  try {
    return JSON.parse(item);
  } catch {}
};
