import styles from './Lookup.module.css';
import variables from '../../theme/variables.module.css';
import logo from '../../../../../assets/images/light_labs_logo.png';
import TestResults from 'components/Lookup/TestResults/TestResults';
import ProductList from 'components/Lookup/ProductList/ProductList';
import LotList from 'components/Lookup/LotList/LotList';
import { Lot, ProductSku } from 'api/types';
import clsx from 'clsx';
import commonStyles from 'theme/base.module.css';
import { JSX } from 'preact';
import { useConfigContext } from '../../context/ConfigContext';
import ProductSearch from 'components/Lookup/ProductSearch/ProductSearch';
import { useState } from 'preact/hooks';

type LookupProps = JSX.IntrinsicElements['div'] & {};

const Lookup = ({ className, ...props }: LookupProps) => {
  const { config } = useConfigContext();
  const { includeFdaLink, lotSearchMode } = config;
  const [selectedLotNumber, setSelectedLotNumber] = useState<Lot>();
  const [selectedSku, setSelectedSku] = useState<ProductSku>();

  return (
    <div {...props} className={clsx(commonStyles.common, styles.lookup, variables.lookup, className)}>
      <div className={styles.results}>
        {!selectedLotNumber && !selectedSku && (
          <ProductSearch
            onSelectSku={setSelectedSku}
            onSelectLot={setSelectedLotNumber}
            lotSearchMode={lotSearchMode}
          />
        )}
        {!selectedLotNumber && selectedSku && (
          <LotList sku={selectedSku} onSelect={setSelectedLotNumber} onBack={() => setSelectedSku(undefined)} />
        )}
        {selectedLotNumber && (
          <TestResults test={selectedLotNumber} sku={selectedSku} onBack={() => setSelectedLotNumber(undefined)} />
        )}
      </div>
      <div className={styles.footer}>
        <div>
          {includeFdaLink && (
            <span>
              Check out the{' '}
              <a
                target="_blank"
                href="https://www.fda.gov/food/environmental-contaminants-food/closer-zero-reducing-childhood-exposure-contaminants-foods"
              >
                FDA Closer to Zero program
              </a>{' '}
              for more information on the importance of product transparency & testing
            </span>
          )}
        </div>
        <div className={styles.powered}>
          Results powered by{' '}
          <a href="https://www.lightlabs.com" target="_blank">
            <img width={80} height={16} src={logo} alt="light labs logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Lookup;
