import { ProductAPI } from 'api/product';
import { Lot, LotSearch } from 'api/types';
import { useQuery } from 'hooks/useQuery';
import { useConfigContext } from '../context/ConfigContext';

type LotSearchHook = {
  isLoading: boolean;
  error?: string;
  lots?: LotSearch[];
};

export const useLotSearch = (lot?: string): LotSearchHook => {
  const { config } = useConfigContext();

  const { isLoading, error, result } = useQuery({
    key: ['searchLots', config.apiKey, lot],
    query: () => ProductAPI.searchLots(config.apiKey, lot),
    isEnabled: !!lot,
  });
  const { lots } = result ?? {};

  return {
    lots,
    error,
    isLoading,
  };
};
