import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productSelect", "testSelect", "samplesList", "subtotal"]
  
  productSelectChanged() {
    if (!this.hasProductSelectTarget || !this.hasTestSelectTarget) return
    this.#refreshSamplesList()
  }
  
  testSelectChanged() {
    if (!this.hasProductSelectTarget || !this.hasTestSelectTarget) return
    this.#refreshSamplesList()
  }
  
  #refreshSamplesList() {
    if (!this.hasSamplesListTarget) return

    try {
      const selectedProducts = Array.from(this.productSelectTarget.selectedOptions || []).map(option => ({
        id: option.value,
        name: option.text.split(' - ')[0]
      }))
      
      const selectedTests = Array.from(this.testSelectTarget.selectedOptions || []).map(option => {
        const [name, price] = option.text.split(' - ')
        return {
          id: option.value,
          name: name,
          price: this.#parsePrice(price)
        }
      })
      
      // Skus and Assays need to be selected
      if (selectedProducts.length && selectedTests.length) {
        selectedProducts.forEach(product => {
          const existingItem = this.#findExistingItem(product.name)
          
          // items with persisted class represent items saved in the database
          if (existingItem && !existingItem.classList.contains('persisted')) {
            this.#updateExistingItem(existingItem, selectedTests)
          } else if (!existingItem) {
            const sampleItem = this.#createSampleItem(product, selectedTests)
            if (sampleItem) {
              this.samplesListTarget.insertBefore(sampleItem, this.samplesListTarget.firstChild)
            }
          }
        })
        
        this.#updateSubtotal()
      } else {
        // Remove all non-persisted items if there are no selected items
        const nonPersistedItems = this.samplesListTarget.querySelectorAll('.list-group-item:not(.persisted)')
        nonPersistedItems.forEach(item => item.remove())
      }
    } catch (error) {
      console.error('Error refreshing samples list:', error)
    }
  }
  
  // If the user already added a test to a product, we need to update the list of tests
  #findExistingItem(productName) {
    if (!this.hasSamplesListTarget || !productName) return null
    
    try {
      const items = this.samplesListTarget.querySelectorAll('.list-group-item')
      return Array.from(items).find(item => {
        const productNameElement = item.querySelector('.product-name')
        return productNameElement && productNameElement.textContent.trim() === productName.trim()
      })
    } catch (error) {
      console.error('Error finding existing item:', error)
      return null
    }
  }
  
  #updateExistingItem(item, newTests) {
    if (!item) return
    
    try {
      const testsList = item.querySelector('.list-group-flush')
      if (testsList && newTests.length) {
        // Cleaning the non persisted items
        const existingTests = testsList.querySelectorAll('.row')
        existingTests.forEach(test => {
          if (!test.classList.contains('persisted')) {
            test.remove()
          }
        })

        newTests.forEach(test => {
          const testRow = this.#createTestRow(test)
          testsList.appendChild(testRow)
        })
      }
    } catch (error) {
      console.error('Error updating existing item:', error)
    }
  }
  
  #createSampleItem(product, tests) {
    if (!product || !tests.length) return null
    
    try {
      const template = document.getElementById('sample-item-template')
      const sampleItem = template.content.cloneNode(true)
      const li = sampleItem.querySelector('li')
      
      li.querySelector('.product-name').textContent = product.name
      
      const testsList = li.querySelector('.list-group-flush')
      tests.forEach(test => {
        const testRow = this.#createTestRow(test)
        testsList.appendChild(testRow)
      })
      
      return li
    } catch (error) {
      console.error('Error creating sample item:', error)
      return null
    }
  }
  
  #createTestRow(test) {
    const template = document.getElementById('test-row-template')
    const testRow = template.content.cloneNode(true)
    const li = testRow.querySelector('li')
    
    li.querySelector('.col-12 span').textContent = test.name
    li.querySelector('.total-item').textContent = this.#formatCurrency(test.price)
    
    return li
  }
  
  #updateSubtotal() {
    if (!this.hasSubtotalTarget || !this.hasSamplesListTarget) return
    
    try {
      let total = 0
      const priceElements = this.samplesListTarget.querySelectorAll('.total-item')
      
      priceElements.forEach(element => {
        const price = this.#parsePrice(element.textContent)
        total += price
      })
      
      if (this.subtotalTarget) {
        this.subtotalTarget.textContent = this.#formatCurrency(total)
      }
    } catch (error) {
      console.error('Error updating subtotal:', error)
    }
  }
  
  #parsePrice(priceString) {
    if (!priceString) return 0
    try {
      const cleanString = priceString.replace(/[^\d.-]/g, '')
      return parseFloat(cleanString) || 0
    } catch (error) {
      console.error('Error parsing price:', error)
      return 0
    }
  }

  #formatCurrency(value) {
    try {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    } catch (error) {
      console.error('Error formatting currency:', error)
      return '$0.00'
    }
  }
}