import styles from 'components/Lookup/BackButton/BackButton.module.css';
import Arrow from 'components/Icons/Arrow';

type BackButtonProps = {
  onClick?: () => void;
};
const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <div className={styles.backButton} onClick={() => onClick?.()}>
      <Arrow />
    </div>
  );
};

export default BackButton;
